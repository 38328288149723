<template>
  <div>
    <v-row>
      <v-col cols="3">
        <h3 class="mb-4">Маршрут в договоре</h3>
      </v-col>
      <v-spacer />

      <v-col>
        <v-btn
          color="primary"
          @click="addRoute"
        >
          Добавить
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      height="400"
      loading-text="Загрузка списка маршрутов"
      item-key="name"
      fixed-header
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.action="{ item }">
        <RouteItem
          :item="item"
          @onRemove="removeRoute"
        ></RouteItem>
      </template>
    </v-data-table>

    <v-row dense>
      <v-col class="text-right">
        <v-btn
          color="primary"
          @click="confirmAdd"
          :loading="loadingConfirm"
          :disabled="items.length === 0"
        >
          Применить
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import RouteItem from "./RouteItem";
export default {
  name: "Routes",
  components: { RouteItem },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loadingConfirm: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Начало действия",
          value: "startDate",
        },
        {
          text: "Окончание действия",
          value: "endDate",
        },
        {
          text: "Маршрут",
          value: "fullName",
        },
        {
          text: "Примечание",
          value: "note",
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      currentRoute: null,
      currentConsist: null,
      applyRoute: null,
    };
  },
  computed: {
    filtered_consist() {
      if (this.currentRoute) {
        return this.consists.filter((consist) => {
          if (
            consist.routeId === this.currentRoute.id &&
            consist.type === 1
          ) {
            this.currentConsist = consist;
          }

          return consist.routeId === this.currentRoute.id;
        });
      } else {
        return this.consists;
      }
    },
  },
  methods: {
    consistText(item) {
      return item.name + " " + item.type;
    },
    changeRoute() {
      this.currentConsist = null;
    },
    addRoute() {
      this.$emit("onAdd");
    },
    removeRoute(route) {
      this.$emit("onRemove", route);
    },
    confirmAdd() {
      this.$emit("onConfirm");
    },
  },
};
</script>
